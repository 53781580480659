import Link from "next/link";
import React, {useState} from "react";
import mailOrderIcon from '../../public/assets/icons/mailOrder.svg';
import shoppingBag from '../../public/assets/icons/shoppingBagIcon.svg';
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import styles from "./MenuMobile.module.scss";

const MenuMobile = ({ menuItems, isOpen, onClickMenuItem }) => {
  const [subExpanded, setSubExpanded] = useState('');
  let mainMenuItems = menuItems.menu.filter(item => !item.id.includes('__'));
  let subItems = menuItems.menu.filter(item => item.id.includes('__'));
  
  let subMenus = {};
  for(let i = 0; i < subItems.length; i++) {
    let k = subItems[i].id.split('__');
    if(subMenus.hasOwnProperty(k[0]))
      subMenus[k[0]].push(subItems[i]);
    else {
      subMenus[k[0]] = [subItems[i]];
      mainMenuItems.find(mitem => mitem.id == k[0])['expandable'] = true;
    }
  }

  let subMenusRender = [];
  for(const k in subMenus) {
    let parentmenu = mainMenuItems.find(mitem => mitem.id == k);
    subMenusRender.push(<div key={'sub_'+parentmenu.title} data-menuid={k} className={styles.menuMobileSubmenu}>
      <div className={styles.menuMobileItem} key={parentmenu.title} onClick={e => setSubExpanded('')}>
        <a className={styles.submenuBack}>{parentmenu['title']}</a>
      </div>
      {subMenus[k].map(d => 
        <div className={styles.menuMobileItem} key={d.title} onClick={onClickMenuItem}>
          <Link key={`menumobile-${d.title}`} href={d.url}>
            <span>
              {d.title}
            </span>
          </Link>
        </div>)}
    </div>);
  }

  return (
  <div className={(isOpen ? `${styles.MenuMobile} ${styles.open}` : styles.MenuMobile)+' '+(subExpanded != '' ? `${styles.subExpanded}` : '')}>
    {mainMenuItems && <div className={styles.menuMobileMain}>
      {mainMenuItems.map((d) => (
        <>
          {d['expandable'] && <div className={styles.menuMobileItem+' '+styles.itemExpandable} key={d.title} onClick={e => setSubExpanded(d.id)}>
              <a data-submenu={d.id}>{d.title}</a>
            </div>}
          {!d['expandable'] && <div className={styles.menuMobileItem} key={d.title} onClick={onClickMenuItem}>
            <Link key={`menumobile-${d.title}`} href={d.url}>
              <span>
                {d.title}
              </span>
            </Link></div>}
        </>
      ))}
      </div>
    }
    {subMenusRender}
    <div className={styles.menuMobileButtonContainer}>
      <ButtonWithIcon
        link={menuItems.buttons[0]}
        customIconComponent={mailOrderIcon}
        showArrow={false}
        outline={true}
        style={{ width: '100%' }}
      />
      {menuItems.buttons[1] &&
        <ButtonWithIcon
          link={menuItems.buttons[1]}
          customIconComponent={shoppingBag}
          showArrow={true}
          outline={true}
          style={{ width: '100%' }}
        />}
    </div>
  </div>
)};

export default MenuMobile;
