import { getCookie } from "cookies-next";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import mailOrderIcon from "../../public/assets/icons/mailOrder.svg";
import shoppingBagIcon from "../../public/assets/icons/shoppingBagIcon.svg";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import styles from "./Header.module.scss";

const Header = ({ headerItems }) => {
  const router = useRouter();
  const [activeLink, setActiveLink] = useState("");
  const [token, setToken] = useState('');
  const [subExpanded, setSubExpanded] = useState('');

  function closeSubmenu(e) {
    console.log('A');
    if(subExpanded != '' && e.target.closest('.sbmenu') == null )
        setSubExpanded('');
  }
  useEffect(() => {
    document.addEventListener('click', closeSubmenu);

    return () => {document.removeEventListener('click', closeSubmenu)}
  }, [subExpanded]);

  useEffect(() => {
    if (getCookie("myarea")) {
      setToken(JSON.parse(getCookie("myarea")).access_token)
    }

  }, [getCookie("myarea")]);


  useEffect(() => {
    if (router && router.asPath) {
      setActiveLink(
        headerItems.menu.filter((d) => router.asPath.includes(d.url))?.pop()
      );
    }
  }, [router]);


  let mainMenuItems = headerItems.menu.filter(item => !item.id.includes('__'));
  let subItems = headerItems.menu.filter(item => item.id.includes('__'));
  
  let subMenus = {};
  for(let i = 0; i < subItems.length; i++) {
    let k = subItems[i].id.split('__');
    if(subMenus.hasOwnProperty(k[0])) {
      subMenus[k[0]].push(subItems[i]);
      if(subItems[i].url == activeLink?.url)
        mainMenuItems.find(mitem => mitem.id == k[0])['active'] = true;
    }
    else {
      subMenus[k[0]] = [subItems[i]];
      mainMenuItems.find(mitem => mitem.id == k[0])['expandable'] = true;
      if(subItems[i].url == activeLink?.url)
        mainMenuItems.find(mitem => mitem.id == k[0])['active'] = true;
    }
  }

  return (
    <div className={styles.darkBackground}>
      <div className={styles.header}>
        <div className={styles.logo}>
          <a href={headerItems.upperHeader.links.logo.url}>
            <img src={headerItems.upperHeader.links.logo.image}></img>
          </a>
        </div>
        {mainMenuItems &&
          mainMenuItems.map((d) => (
            <div
              key={d.title}
            >
              {d.title.includes('My Mall Magazine') ?

                <a key={d.title} href={`${d.url + '?a_t=' + token}`} style={{ color: d.url === activeLink?.url ? "#fff" : undefined }}>
                  <span>{d.title}</span>
                </a>
                :
                (d['expandable'] ? 
                <span className={styles.expandableItem + (subExpanded == d.id ? ' ' + styles.expandableItemExpanded : '')}>
                  <a key={d.title} style={{ color: d.url === activeLink?.url || d.active ? "#fff" : undefined }} onClick={e => setSubExpanded(d.id)}>
                  {d.title}
                  </a>
                  <div className={styles.subMenu + ' sbmenu'} style={{display: (subExpanded == d.id ? 'flex' : 'flex')}}>
                    <div style={{padding: '100px 0 50px 0'}}>
                      <div className={styles.closeContainer}><span className={styles.close} onClick={e => setSubExpanded('')}></span></div>
                      {subMenus[d.id].map(sitem => <a key={'subd_'+sitem.title} href={sitem.url} style={{ color: sitem.url === activeLink?.url ? "#fff" : undefined }}>{sitem.title}</a>)}
                    </div>
                  </div>
                </span>
                 : 
                <a key={d.title} href={d.url} style={{ color: d.url === activeLink?.url ? "#fff" : undefined }}>
                  <span>{d.title}</span>
                </a>)
              }
            </div>
          ))}
        <div className={styles.buttonContainer}>
          <ButtonWithIcon
            link={headerItems.buttons[0]}
            showArrow={false}
            outline={true}
          />
          {/* <ButtonWithIcon
            link={headerItems.buttons[1]}
            customIconComponent={shoppingBagIcon}
            showArrow={true}
            outline={true}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
