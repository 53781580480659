import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import UserIcon from "../../public/assets/icons/userIcon.svg";
import MenuMobile from "../MenuMobile/MenuMobile";
import styles from "./UpperHeader.module.scss";
import { useRouter } from "next/router";
import { getCookie } from "cookies-next";
import { fetchData } from "../../fetcher";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon
        icon={faCaretDown}
        size="xs"
        style={{ margin: "0 .35rem", fontSize: ".65rem" }}
      />
    </components.DropdownIndicator>
  );
};

const UpperHeader = ({ menuItems }) => {
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);
  const [logo, setLogo] = useState(null);
  const [menu, setMenu] = useState([]);
  const [langOptions, setLangOptions] = useState([]);
  const [currentLocale, setCurrentLocale] = useState(0);
  const [myArea, setMyArea] = useState();
  const router = useRouter();
  const [redirect, setRedirect] = useState();

  useEffect(() => {
    init();
  }, [getCookie("myarea")]);

  const init = async () => {
    const properties = await fetchData(
      null,
      router.query.locale,
      "GET",
      null,
      null,
      null,
      true
    );

    if (getCookie("myarea")) {
      if (JSON.parse(getCookie("myarea")).loyalty) {
        setRedirect(properties.REDIRECT_PERSONALPAGE);
      }else{
        setRedirect(properties.REDIRECT_MYINFORMATION);
      }
    } else {
      setRedirect(properties.REDIRECT_LOGIN);
    }
  };

  const colorStyles = {
    option: (styles, { isFocused, isSelected }) => {
      const bgColor = "#B1A38A"; // gold
      const focusedColor = "#857E70"; // light-gray
      const textColor = "#FFFFFF"; // white

      if (isSelected) {
        return {
          ...styles,
          backgroundColor: bgColor,
        };
      }

      return {
        ...styles,
        color: textColor,
        backgroundColor: isFocused ? focusedColor : null,
      };
    },
  };

  useEffect(() => {
    let langs = [];

    let locale = "en";
    let spl = router.asPath.split("/");
    if (spl && spl.length > 1 && spl[1].trim().length > 0) locale = spl[1];
    router.query.locales.forEach((el, i) => {
      if (el == locale) setCurrentLocale(i);
      langs.push({
        label: el.toUpperCase(),
        value: el.toUpperCase(),
      });
    });
    setLangOptions(langs);

    if (getCookie("myarea")) {
      const name = JSON.parse(getCookie("myarea")).first_name ? JSON.parse(getCookie("myarea")).first_name : ""
      if (JSON.parse(getCookie("myarea")).access_token) {
        setMyArea(
          menuItems.upperHeader.links.my_area.title_login +
            " " +
            name
        );
      } else {
        setMyArea(menuItems.upperHeader.links.my_area.title_logout);
      }
    } else {
      setMyArea(menuItems.upperHeader.links.my_area.title_logout);
    }
  }, []);

  useEffect(() => {
    if (openBurgerMenu) {
      document.documentElement.classList.add(styles.blockDocument);
      document.body.classList.add(styles.blockDocument);
    } else {
      document.documentElement.classList.remove(styles.blockDocument);
      document.body.classList.remove(styles.blockDocument);
    }
  }, [openBurgerMenu]);

  useEffect(() => {
    if (menuItems?.upperHeader?.links?.logo) {
      setLogo(menuItems.upperHeader.links.logo);
      setMenu(menuItems.menu);
    }
  }, [menuItems]);

  const handleLocaleChange = (event) => {
    let query = router.query;
    query.locale = event.value.toLowerCase();
    location.href = `/${query.locale}/`;
  };

  return (
    <>
      <MenuMobile
        menuItems={menuItems}
        isOpen={openBurgerMenu}
        onClickMenuItem={() => setOpenBurgerMenu(!openBurgerMenu)}
      />
      <div className={styles.upperHeaderDesktop}>
        {langOptions.length > 0 && (
          <div className={styles.langSelector}>
            <label htmlFor="lang-selector">
              {menuItems.upperHeader.navigate}
            </label>
            <Select
              components={{ DropdownIndicator }}
              styles={colorStyles}
              isSearchable={false}
              instanceId="select-desktop"
              classNamePrefix={styles.localeSelect}
              className={styles.localeSelect}
              options={langOptions}
              defaultValue={langOptions[currentLocale]}
              onChange={handleLocaleChange}
            />
          </div>
        )}
        <div className={styles.personal}>
          <span className={styles.personalIcon}>
            <UserIcon />
          </span>
          <a href={redirect}>{myArea ? myArea : ""}</a>
        </div>
      </div>
      <div className={styles.upperHeaderMobile}>
        {langOptions.length > 0 && (
          <div>
            <label htmlFor="lang-selector"></label>
            <Select
              components={{ DropdownIndicator }}
              styles={colorStyles}
              isSearchable={false}
              instanceId="select-mobile"
              classNamePrefix={styles.localeSelect}
              className={styles.localeSelect}
              options={langOptions}
              defaultValue={langOptions[currentLocale]}
              onChange={handleLocaleChange}
            />
          </div>
        )}
        {logo ? (
          <a className={styles.upperHeaderLogo} href={logo.url}>
            <img src={logo.image} alt="The Mall Logo" />
          </a>
        ) : null}
        <div className={styles.burgerContainer}>
          <a className={styles.personalIcon} href={redirect}>
            <UserIcon />
          </a>
          <div
            className={styles.burgerIcons}
            style={{ zIndex: "10000" }}
            onClick={() => setOpenBurgerMenu(!openBurgerMenu)}
          >
            <div className={openBurgerMenu ? styles.open : null}></div>
            <div className={openBurgerMenu ? styles.open : null}></div>
            <div className={openBurgerMenu ? styles.open : null}></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpperHeader;
