import Head from "next/head";
import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import UpperHeader from "../../components/UpperHeader/UpperHeader";
import Loader from "../../components/Loader/Loader";
import { ApplicationContextProvider } from "../../context/ApplicationContext";

const TheMallOutletLayout = ({ menuItems, app = false, children }) => (
  <>
    <Loader />
    {menuItems && (
      <>
        {!app && (
          <>
            <UpperHeader menuItems={menuItems.header} />
            <Header headerItems={menuItems && menuItems.header} />
          </>
        )}
        <div id="global-container">{children}</div>
        {!app && <Footer menuItems={menuItems} />}
      </>
    )}
  </>
);

TheMallOutletLayout.propTypes = {};

TheMallOutletLayout.defaultProps = {};

export default TheMallOutletLayout;
