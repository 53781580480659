import React, { Fragment, useEffect, useState, useContext } from "react";
import useWindowResize from "../../hooks/useWindowResize";
import ArrowRight from "../../public/assets/icons/arrowRight.svg";
import styles from "./Footer.module.scss";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import shoppingBagIcon from "../../public/assets/icons/shoppingBagIcon.svg";
import { getCookie } from "cookies-next";
import { ApplicationContext } from "../../context/ApplicationContext";
import { fetchData } from "../../fetcher";
import { useRouter } from "next/router";
import Swal from "sweetalert2";
import CloseIcon from "../../public/assets/icons/closeIcon.svg";
import ReactDOMServer from "react-dom/server";

const Footer = ({ menuItems }) => {
  const [logo, setLogo] = useState(null);
  const [img, setImg] = useState(null);
  const [linkSection, setLinkSection] = useState();
  const [infoButton, setInfoButton] = useState();
  const [download, setDownload] = useState();
  const isMobile = useWindowResize(568);
  const isLarge = useWindowResize(1200);
  const Intermediate = useWindowResize(1372);
  const [token, setToken] = useState();
  const { setShowLoader } = useContext(ApplicationContext);
  const router = useRouter();
  const [properties, setProperties] = useState();

  useEffect(() => {
    if (
      menuItems &&
      menuItems.header &&
      menuItems.header.upperHeader.links.logo
    ) {
      setLogo(menuItems.header.upperHeader.links.logo);
    }

    // controllo presenza cookie per mettere token nel redirect
    if (getCookie("myarea")) {
      setToken(JSON.parse(getCookie("myarea")).access_token);
    }
  }, [menuItems]);

  useEffect(() => {
    init()
    for (var l in menuItems.footer) {
      if (menuItems.footer[l].type === "download_app") {
        setDownload(menuItems.footer[l]);
        break;
      }
    }
  }, [menuItems.footer]);

  useEffect(() => {
    let l = [];
    {
      menuItems.footer_info.links.map((d, i) => {
        if (d.type != "internal") {
          l.push(
            <a href={d.url} key={i} target={d.target}>
              {d.title}
            </a>
          );
        } else {
          setInfoButton(
            <div key={i} className={styles.infoButton}>
              <ButtonWithIcon link={d} outline />
            </div>
          );
        }
      });
    }
    setLinkSection(l);
  }, [menuItems.footer_info.links]);

  useEffect(() => {
    if (menuItems && menuItems.beyond.image && menuItems.beyond.image_mobile) {
      setImg(
        !isMobile ? menuItems.beyond.image : menuItems.beyond.image_mobile
      );
    }
  }, [menuItems, isMobile]);
  const init = async () => {
    const properties = await fetchData(
      null,
      router.query.locale,
      "GET",
      null,
      null,
      null,
      true
    );

    setProperties(properties)
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);

    const properties = await fetchData(
      null,
      router.query.locale,
      "GET",
      null,
      null,
      null,
      true
    );

    const res = await fetch(
      `${process.env.NEXT_PUBLIC_FE_COMMON}/newsletter`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          country: router.query.locale.toUpperCase(),
          email: e.target.email.value,
          source: window.location.host,
        }),
      }
    );

    const resjs = await res.json();
    setShowLoader(false);

    if (resjs?.message) {
      Swal.fire({
        title: properties[resjs.message],
        showCloseButton: true,
        showCancelButton: false,
        showConfirmButton: false,
        closeButtonHtml: ReactDOMServer.renderToString(<CloseIcon />),
      });
    }

    e.target.reset()
  };

  return (
    <div className={styles.footer}>
      <div>
        {/* <div
          className={styles.footerImg}
          style={{
            backgroundImage: img ? `url(${img.src})` : null,
          }}
        >
          <div className={styles.darkBackground}>
            <div className={styles.heroContent}>
              <div className={styles.title}>
                {menuItems.beyond.title.toUpperCase()}
              </div>
              {menuItems.beyond.subtitle}
              <div className={styles.button}>
                <ButtonWithIcon
                  link={menuItems.beyond.link}
                  customIconComponent={shoppingBagIcon}
                  showArrow={true}
                  outline={true}
                />
              </div>
            </div>
          </div>
        </div> */}
        <div className={`${styles.footerBrand} ${styles.footerBrandInfo}`}>
          <div
            className={styles.container}
            style={{ padding: isLarge ? null : "0 2.25rem" }}
          >
            {logo && logo.url && logo.image && (
              <a href={logo.url}>
                <img src={logo.image} />
              </a>
            )}
            <div className={styles.footerClub}>
              <span className={styles.footerClubTitle}>
                {menuItems.club?.title}
              </span>
              <span className={`text-gold ${styles.footerClubDesc}`}>
                {menuItems.club?.subtitle}
              </span>
            </div>
            {menuItems.club.link && (
              <div className={styles.clubButton}>
                <ButtonWithIcon link={menuItems.club.link} outline={true} />
              </div>
            )}
          </div>
        </div>
        {isMobile && <div
          className={styles.footerBrandNewsletter}
          style={{
            position: Intermediate && !isLarge ? "relative" : null,
            left: "calc(100% - 22.5rem)",
            bottom: "130px",
          }}
        >
          <div className={styles.footerBrandNewsletterTitle}>
            <span>{menuItems?.newsletter?.title}</span>
            <span className="text-gold">
              {menuItems?.newsletter?.subtitle}
            </span>
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <label className="text-gray" htmlFor="yourEmail">
              {menuItems?.newsletter?.text}
              <label className={styles.newsletterInput}>
                <input
                  name="email"
                  type={menuItems?.newsletter?.field?.type}
                  placeholder=" "
                  autoComplete="off"
                />
                <span className={styles.placeholder}>
                  {menuItems?.newsletter?.field?.placeholder}
                </span>
                <button type="submit">
                  <ArrowRight style={{ display: "block" }} />
                </button>
              </label>
            </label>
          </form>
          <div className={styles.mailContainer} dangerouslySetInnerHTML={properties && { __html: properties.FORM_NL_PRIVACY }}></div>
        </div>
        }
      </div>
      <div className={`${styles.footerBrand} ${styles.footerBrandRef}`}>
        <div
          className={styles.container}
          style={{ padding: isLarge ? null : "0 2.25rem" }}
        >
          <div
            className={styles.footerBrandRefLinks}
            style={{
              // justifyContent: Intermediate && !isLarge ? "space-between" : null,
              width: Intermediate && !isLarge ? "100%" : null,
            }}
          >
            <div className={styles.footerFirstSection}>
              <div className={styles.footerBrandRefLinkTitle}>
                {menuItems.footer_info.title}
              </div>
              <div className={styles.footerFirstSectionText}>
                {menuItems.footer_info.address}
                <div>{menuItems.footer_info.opening}</div>
                {menuItems.footer_info.close}
              </div>
              <div className={styles.linksContainer}>
                {linkSection && linkSection.map((d) => d)}
              </div>
              <div className={styles.info}>{menuItems.footer_info.info}</div>
              {infoButton}
            </div>
            {menuItems.footer &&
              menuItems.footer.map((d, i) => {
                if (d.type === "our_world" || d.type === "legal_area") {
                  return (
                    <div key={d.type + "-" + i} className={styles.footerColumn}>
                      <div className={styles.footerBrandRefLinkTitle}>
                        {d.title}
                      </div>
                      <div className={styles.footerLink}>
                        {d.links.map((link, j) => {
                          if (link?.image) {
                            return (
                              <div
                                key={i + "-" + j + "-footerLink"}
                                className={styles.upperHeaderLogo}
                              >
                                <a href={link.url} target={link.target}>
                                  <img src={link?.image} alt={link.title} />
                                </a>
                              </div>
                            );
                          }
                          if (link?.title?.toLowerCase()?.includes("beyond")) {
                            return (
                              <p key={i + "-" + j + "-footerLink"}>
                                <a href={link.url} target={link.target}>
                                  {link.title}
                                </a>
                                <ArrowRight
                                  style={{
                                    transform: "rotate(-45deg)",
                                    marginLeft: ".5rem",
                                  }}
                                />
                              </p>
                            );
                          }
                          return (
                            <p key={i + "-" + j + "-footerLink"}>
                              <a
                                href={`${link.url}${token &&
                                  (link.title.includes("My Mall") ||
                                    link.title.includes("The Mall Luxury Outlets") ||
                                    link.title.includes("The Mall Firenze") ||
                                    link.title.includes("The Mall Sanremo"))
                                  ? "?a_t=" + token
                                  : ""
                                  }`}
                                target={link.target}
                              >
                                {link.title}
                              </a>
                            </p>
                          );
                        })}
                        {d.type=='legal_area' && 
                          <button style={{fontFamily:'inherit', fontSize: 'inherit', padding: 0, border:'none',color: 'inherit', backgroundColor: 'transparent'}} id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
                        }
                      </div>
                    </div>
                  );
                } else if (d.type === "your_mall") {
                  if (download) {
                    return (
                      <div key={d.type + "-" + i}>
                        <div
                          className={styles.footerColumnWrap}
                          style={{
                            display: Intermediate && !isLarge ? "none" : null,
                          }}
                        >
                          <div className={styles.footerColumn}>
                            <div className={styles.footerBrandRefLinkTitle}>
                              {d.title}
                            </div>
                            <div className={styles.footerLink}>
                              {d.links.map((link, j) => {
                                if (link?.image) {
                                  return (
                                    <div
                                      key={"email-link-" + j}
                                      className={styles.upperHeaderLogo}
                                    >
                                      <a href={link.url} target={link.target}>
                                        <img
                                          src={link?.image}
                                          alt={link.title}
                                        />
                                      </a>
                                    </div>
                                  );
                                }
                                if (
                                  link?.title?.toLowerCase()?.includes("beyond")
                                ) {
                                  return (
                                    <p key={"email-link-" + j}>
                                      <a href={link.url} target={link.target}>
                                        {link.title}
                                      </a>
                                      <ArrowRight
                                        style={{
                                          transform: "rotate(-45deg)",
                                          marginLeft: ".5rem",
                                        }}
                                      />
                                    </p>
                                  );
                                }

                                return (
                                  <p key={"email-link-" + j}>
                                    <a href={link.url} target={link.target}>
                                      {link.title}
                                    </a>
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                          <div
                            key={download.type}
                            className={styles.footerColumn}
                            style={{ display: isLarge ? "none" : null }}
                          >
                            <div className={styles.footerBrandRefLinkTitle}>
                              {download.title}
                            </div>
                            <div className={styles.footerLink}>
                              {download.links.map((link, j) => {
                                if (link?.image) {
                                  return (
                                    <div
                                      key={"download-link-" + j}
                                      className={styles.upperHeaderLogo}
                                    >
                                      <a href={link.url} target={link.target}>
                                        <img
                                          src={link?.image}
                                          alt={link.title}
                                        />
                                      </a>
                                    </div>
                                  );
                                }
                                if (
                                  link?.title?.toLowerCase()?.includes("beyond")
                                ) {
                                  return (
                                    <p key={"download-link-" + j}>
                                      <a href={link.url} target={link.target}>
                                        {link.title}
                                      </a>
                                      <ArrowRight
                                        style={{
                                          transform: "rotate(-45deg)",
                                          marginLeft: ".5rem",
                                        }}
                                      />
                                    </p>
                                  );
                                }

                                return (
                                  <p key={"download-link-" + j}>
                                    <a href={link.url} target={link.target}>
                                      {link.title}
                                    </a>
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        </div>

                        <div style={{ display: "flex", gap: "6.25rem", flexDirection: "column" }}>
                          <div
                            className={styles.footerColumn}
                            key={d.type}
                            style={{
                              display: Intermediate && !isLarge ? null : "none",
                            }}
                          >
                            <div
                              key={d.type}
                              className={styles.footerBrandRefLinkTitle}
                            >
                              {d.title}
                            </div>
                            <div className={styles.footerLink}>
                              {d.links.map((link, j) => {
                                if (link?.image) {
                                  return (
                                    <div
                                      key={"col-link-" + j}
                                      className={styles.upperHeaderLogo}
                                    >
                                      <a href={link.url} target={link.target}>
                                        <img
                                          src={link?.image}
                                          alt={link.title}
                                        />
                                      </a>
                                    </div>
                                  );
                                }
                                if (
                                  link?.title?.toLowerCase()?.includes("beyond")
                                ) {
                                  return (
                                    <p key={"col-link-" + j}>
                                      <a href={link.url} target={link.target}>
                                        {link.title}
                                      </a>
                                      <ArrowRight
                                        style={{
                                          transform: "rotate(-45deg)",
                                          marginLeft: ".5rem",
                                        }}
                                      />
                                    </p>
                                  );
                                }

                                return (
                                  <p key={"col-link-" + j}>
                                    <a href={link.url} target={link.target}>
                                      {link.title}
                                    </a>
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                          <div
                            key={download.type}
                            className={styles.footerColumn}
                            style={{
                              display: isLarge || !Intermediate ? "none" : null,
                            }}
                          >
                            <div className={styles.footerBrandRefLinkTitle}>
                              {download.title}
                            </div>
                            <div className={styles.footerLink}>
                              {download.links.map((link, j) => {
                                if (link?.image) {
                                  return (
                                    <div
                                      key={"downloads-" + j}
                                      className={styles.upperHeaderLogo}
                                    >
                                      <a href={link.url} target={link.target}>
                                        <img
                                          src={link?.image}
                                          alt={link.title}
                                        />
                                      </a>
                                    </div>
                                  );
                                }
                                if (
                                  link?.title?.toLowerCase()?.includes("beyond")
                                ) {
                                  return (
                                    <p key={"downloads-" + j}>
                                      <a href={link.url} target={link.target}>
                                        {link.title}
                                      </a>
                                      <ArrowRight
                                        style={{
                                          transform: "rotate(-45deg)",
                                          marginLeft: ".5rem",
                                        }}
                                      />
                                    </p>
                                  );
                                }

                                return (
                                  <p key={"downloads-" + j}>
                                    <a href={link.url} target={link.target}>
                                      {link.title}
                                    </a>
                                  </p>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={d.type} className={styles.footerColumn}>
                        <div className={styles.footerBrandRefLinkTitle}>
                          {d.title}
                        </div>
                        <div className={styles.footerLink}>
                          {d.links.map((link, j) => {
                            if (link?.image) {
                              return (
                                <div
                                  key={"downloads-col-" + j}
                                  className={styles.upperHeaderLogo}
                                >
                                  <a href={link.url} target={link.target}>
                                    <img src={link?.image} alt={link.title} />
                                  </a>
                                </div>
                              );
                            }
                            if (
                              link?.title?.toLowerCase()?.includes("beyond")
                            ) {
                              return (
                                <p key={"downloads-col-" + j}>
                                  <a href={link.url} target={link.target}>
                                    {link.title}
                                  </a>
                                  <ArrowRight
                                    style={{
                                      transform: "rotate(-45deg)",
                                      marginLeft: ".5rem",
                                    }}
                                  />
                                </p>
                              );
                            }

                            return (
                              <p key={"downloads-col-" + j}>
                                <a href={link.url} target={link.target}>
                                  {link.title}
                                </a>
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                }
              })}
          </div>
          {download && (
            <div
              className={`${styles.footerColumn} ${styles.download}`}
              style={{ display: isLarge ? null : "none" }}
            >
              <div className={styles.footerBrandRefLinkTitle}>
                {download.title}
              </div>
              <div className={styles.footerLink}>
                {download.links.map((link, j) => {
                  if (link?.image) {
                    return (
                      <div
                        key={"download-upper-" + j}
                        className={styles.upperHeaderLogo}
                      >
                        <a href={link.url} target={link.target}>
                          <img src={link?.image} alt={link.title} />
                        </a>
                      </div>
                    );
                  }
                  if (link?.title?.toLowerCase()?.includes("beyond")) {
                    return (
                      <p key={"download-upper-" + j}>
                        <a href={link.url} target={link.target}>
                          {link.title}
                        </a>
                        <ArrowRight
                          style={{
                            transform: "rotate(-45deg)",
                            marginLeft: ".5rem",
                          }}
                        />
                      </p>
                    );
                  }

                  return (
                    <p key={"download-upper-" + j}>
                      <a href={link.url} target={link.target}>
                        {link.title}
                      </a>
                    </p>
                  );
                })}
              </div>
            </div>
          )}
          {!isMobile &&
            <div
              className={styles.footerBrandNewsletter}
              style={{
                position: Intermediate && !isLarge ? "relative" : null,
                left: "calc(100% - 22.5rem)",
                bottom: "130px",
              }}
            >
              <div className={styles.footerBrandNewsletterTitle}>
                <span>{menuItems?.newsletter?.title}</span>
                <span className="text-gold">
                  {menuItems?.newsletter?.subtitle}
                </span>
              </div>
              <form onSubmit={(e) => handleSubmit(e)}>
                <label className="text-gray" htmlFor="yourEmail">
                  {menuItems?.newsletter?.text}
                  <label className={styles.newsletterInput}>
                    <input
                      name="email"
                      type={menuItems?.newsletter?.field?.type}
                      placeholder=" "
                      autoComplete="off"
                    />
                    <span className={styles.placeholder}>
                      {menuItems?.newsletter?.field?.placeholder}
                    </span>
                    <button type="submit">
                      <ArrowRight style={{ display: "block" }} />
                    </button>
                  </label>
                </label>
              </form>
              <div className={styles.mailContainer} dangerouslySetInnerHTML={properties && { __html: properties.FORM_NL_PRIVACY }}></div>
            </div>}
        </div>
      </div>
      <div className={styles.footerLow}>
        <div className={styles.container}>
          <div>
            <div className={styles.footerLowSocialContainer}>
              <span className="text-gold">{menuItems.social.title}</span>
              {menuItems.social.items &&
                menuItems.social.items.map((d, i) =>
                  d.title == "facebook" ? (
                    <div
                      key={`sidenavSocial${i}`}
                      className={styles.socialIconContainer}
                    >
                      <div className={styles.facebookIcons}>
                        {d.links.map((l, j) => (
                          <a key={j} href={l.url} target={l.target}>
                            <img src={d.image} alt="Facebook" />
                            <span>{l.title}</span>
                          </a>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div
                      key={`sidenavSocial${i}`}
                      className={styles.socialIconContainer}
                    >
                      <a href={d?.url} target={d?.target}>
                        <img src={d?.image} alt={d?.title} />
                      </a>
                    </div>
                  )
                )}
            </div>
          </div>
          <div
            className={styles.copywright}
            dangerouslySetInnerHTML={{ __html: menuItems?.disclaimer?.html }}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
