import React, { useContext, useEffect } from 'react';
import { ApplicationContext } from '../../context/ApplicationContext';
import styles from './Loader.module.scss';

const Loader = () => {
  const { showLoader } = useContext(ApplicationContext)
  useEffect(() => {
    if (showLoader) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [showLoader])
  
  return (
    <div className={styles.Loader + ' ' + (!showLoader ? styles.invisible : null)}>
      <div className={styles.spinner}>
        <div className={styles.doubleBounce1}></div>
        <div className={styles.doubleBounce2}></div>
      </div>
    </div>
  );
}


export default Loader;
